import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import { Maybe } from '@emico/graphql-schema-types'
import { ButtonUnstyled, H1, H2 } from '@emico/ui'

import ChevronLeftIcon from '../icons/ChevronLeftIcon'
import CrossIcon from '../icons/CrossIcon'
import { CraftImageFragment } from '../lib/craftFragments.generated'
import theme from '../theme'
import CraftImage from './CraftImage'

const Header = styled.header`
  display: grid;
  align-items: end;
`

const Buttons = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: ${theme.zIndices.modal};
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  padding: ${theme.spacing.md};
`

const CloseButton = styled(StyledButtonUnstyled)`
  margin-left: auto;
`

const iconStyling = css`
  font-size: 14px;
  color: ${theme.colors.white};
`

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  ${iconStyling};
`

const StyledCrossIcon = styled(CrossIcon)`
  ${iconStyling};
`

const Figure = styled.figure`
  grid-row: 1;
  grid-column: 1;
  margin: 0;
  ${theme.stylingSnippet.transparentOverlay()};
`

const StyledCraftImageMobileAndTablet = styled(CraftImage)`
  width: 100%;
  height: ${theme.sizes.menuMobileHeaderHeight};
  object-fit: cover;
`

const titleStyling = css`
  grid-row: 1;
  grid-column: 1;
  margin: 0 0 ${theme.spacing.md} ${theme.spacing.md};
  position: relative;
  z-index: 1;
  font-size: ${theme.fontSizes['2xl']};
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.textLight};
`

const Title = styled(H1)`
  ${titleStyling}
`

const Subtitle = styled(H2)`
  ${titleStyling}
`

interface Props {
  title: string
  isSubmenu?: boolean
  image?: Maybe<CraftImageFragment>
  close: () => void
  back?: () => void
}

const MenuMobileHeader = ({ title, isSubmenu, image, close, back }: Props) => (
  <Header>
    <Buttons>
      {isSubmenu && back && (
        <StyledButtonUnstyled
          analyticsContext="menu.mobile.modal"
          analyticsName="back"
          onClick={back}
        >
          <StyledChevronLeftIcon />
        </StyledButtonUnstyled>
      )}

      <CloseButton
        analyticsContext="menu.mobile.modal"
        analyticsName="close"
        onClick={close}
      >
        <StyledCrossIcon />
      </CloseButton>
    </Buttons>

    {image && (
      <Figure>
        <StyledCraftImageMobileAndTablet
          image={image}
          type="mobile"
          height={175}
        />
      </Figure>
    )}

    {isSubmenu ? <Subtitle>{title}</Subtitle> : <Title>{title}</Title>}
  </Header>
)

export default MenuMobileHeader
