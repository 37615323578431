import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

import { maxWidth, minWidth } from '@emico/styles'

import theme from '../theme'
import Modal, { ModalBody, TitleWrapper } from './Modal'
import StoreSelectorCountryList from './StoreSelectorCountryList'

const StyledModalMobile = styled(Modal)`
  @media ${minWidth('md')} {
    display: none;
  }
`

const StyledModalTabletAndDesktop = styled(Modal)`
  ${ModalBody} {
    @media ${minWidth('md')} {
      height: auto;
      max-height: 90vh;
      max-width: 600px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  ${TitleWrapper} {
    @media ${minWidth('lg')} {
      padding-top: ${theme.spacing.xl};
    }
  }

  @media ${maxWidth('sm')} {
    display: none;
  }
`

const StyledStoreSelectorCountryList = styled(StoreSelectorCountryList)`
  margin-top: ${theme.spacing.lg};
`

interface Props {
  show: boolean
  close: () => void
}

const StoreSelectorModal = ({ show, close }: Props) => (
  <>
    <StyledModalMobile
      title={<Trans>From where do you want to fly, jump or ride?</Trans>}
      show={show}
      close={close}
      showMobileBackdrop
      renderBeforeShow
      segueType="right"
    >
      <StyledStoreSelectorCountryList isDisabled={!show} />
    </StyledModalMobile>

    <StyledModalTabletAndDesktop
      title={<Trans>From where do you want to fly, jump or ride?</Trans>}
      show={show}
      close={close}
      renderBeforeShow
    >
      <StyledStoreSelectorCountryList isDisabled={!show} />
    </StyledModalTabletAndDesktop>
  </>
)

export default StoreSelectorModal
